<template>
    <div class="home row">
        <div class="notice-ul">
            <!--通知时间-->
<!--            <div class="notice-time-box">-->
<!--                2021-08-31    16:00-->
<!--            </div>-->
            <div class="notice-li" v-for="(item,index) in noticelist" :key="index">
                <div class="notice-li-left">
                    <img src="../assets/imgaes/laba.png" alt="">
                    <span>{{$t('GroupQuestions.Notice')}}</span>
                </div>
                <div class="notice-li-right">
                    <span>{{item.content}}</span>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: "Notice",
        data(){
            return{
                noticelist:[]
            }
        },
        created() {
            this.getNoticeList()
        },
        methods:{
            // 获取通知接口
            getNoticeList(){
                this.$http.post('notice/list').then(res =>{
                    console.log(res)
                    this.noticelist = res.data.list
                    this.getReadSign()
                })
            },
            //获取通知已读  notice/read_sign
            getReadSign(){
                this.$http.post('notice/read_sign').then(res =>{
                    console.log(res)
                    // this.noticelist = res.data.list
                })
            },
        }
    }
</script>

<style scoped lang="less">
.home{
    padding: 39px 44px;
    .notice-ul{
        width: 100%;
        .notice-li{
            width: 100%;
            height: auto;
            display: flex;
            box-shadow: 0px 8px 8px 1px rgba(126, 126, 127, 0.17);
            border-radius: 5px;
            padding:20px;
            margin-bottom: 20px;
            .notice-li-left{
                font-size: 20px;
                color: #666666;
                margin-right: 12px;
                img{
                    margin-right: 11px;
                }
            }
            .notice-li-right{
                width: 50%;
                font-size: 16px;
                color: #666666;
                min-height: 100%;
                span{
                    display: inline-block;
                    text-align: justify;
                }
            }
        }
        .notice-time-box{
            width: 376px;
            height: 35px;
            background: #E1E1E1;
            border-radius: 6px;
            color: #ffffff;
            font-size: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            margin-bottom: 20px;
        }
    }

}

@media screen and  (min-width:0px) and (max-width: 900px) {
    .home{
        padding: 10px 20px;
    }
    .home .notice-ul .notice-li{
        width: 100%;
        height: auto;
        display: block !important;
    }
}

</style>